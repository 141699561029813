import React from "react"

import Footer from "./Footer"
import SkipLinks from "../SkipLinks"
import Header from "./Header"

export const Layout = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => (
  <div className="flex flex-col items-stretch min-h-screen">
    <SkipLinks />
    <Header />
    <main id="main" role="main" className="flex-grow px-4 md:px-8">
      {children}
    </main>
    <Footer />
  </div>
)
