import Link from "next/link"

import "@gouvfr/dsfr/dist/component/header/header.min.css"
import "@gouvfr/dsfr/dist/component/logo/logo.min.css"
import "@gouvfr/dsfr/dist/component/link/link.min.css"

const Header = () => {
    return (
        <header role="banner" className="fr-header">
        <div className="fr-header__body">
          <div className="fr-container">
            <div className="fr-header__body-row">
            <div className="fr-header__brand fr-enlarge-link">
                <div className="fr-header__brand-top">
                  <div className="fr-header__logo">
                    <p className="fr-logo">
                      Ministère
                      <br />
                      de la santé
                      <br />
                      et de la prévention
                    </p>
                  </div>
                </div>
                <div className="fr-header__service">
                  <Link
                    href="/"
                    passHref
                    title="Accueil - Observatoire National des Violences en Santé - Ministère de la santé et de la prévention"
                  >
                    <p className="fr-header__service-title">
                      Observatoire National des Violences en Santé
                    </p>
                  </Link>
                  <p className="fr-header__service-tagline">
                    Plateforme de signalement
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
}

export default Header