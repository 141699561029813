import {
  AuthorSchema,
  DeclarationModel,
  DeclarationModelWithEditor,
  DeclarationModelWithFiness,
  PursuitSchema,
  VictimSchema,
} from "@/models/declarations"
import { Prisma } from "@prisma/client"
import { format, parseISO } from "date-fns"
import { jsPDF } from "jspdf"

// Entête du PDF
const pdfHeader = "DGOS - ONVS (Observatoire National des Violences en Santé)"

// Position minimale pour déclencher un saut de page
const pageBreakLine = 280

// Facteur pour déterminer la longueur maximale d'une valeur par rapport à la page
const splitFactor = 2

// Police par défaut
const defaultFontName = "helvetica"
const defaultFontSize = 10

// "Marges" du PDF
const marginLeft = 10
const startLine = 15
const defaultSpacing = 5

// Section
const sectionSpacing = 10
const sectionTitleColor = "#dbeafe"
const sectioncFontSize = 13
const sectionHeightSize = 7

// Texte
const textLabelPosition = marginLeft
const textValuePosition = marginLeft + 65

export const getDeclarationPDF = async (
  declaration: DeclarationModelWithFiness,
) => {
  // Initialisation du PDF
  const pdf = new jsPDF("p", "mm", [297, 210])
  addImageConfidential(pdf)
  let line = startLine

  // ########## Titre ##########
  pdf.setFont(defaultFontName, "normal", "bold")
  pdf.text(pdfHeader, marginLeft, line)

  // ########## Votre déclaration ##########
  line = addSection(pdf, line, "Votre déclaration")

  // Type de déclaration
  line = addLabelAndValue(
    pdf,
    line,
    "Type de déclaration",
    true,
    getDeclarationType(declaration),
  )

  if (declaration.editorId) {
    // Éditeur
    line = addLabelAndValue(
      pdf,
      line,
      "Éditeur",
      true,
      (declaration as DeclarationModelWithEditor).editor?.name || "N/A",
    )

    // FINESS
    line = addLabelAndValue(
      pdf,
      line,
      "FINESS",
      true,
      declaration.finesset || "N/A",
    )
  }

  if (declaration.finessId) {
    // Établissement
    line = addLabelAndValue(
      pdf,
      line,
      "Établissement",
      true,
      (declaration as DeclarationModelWithFiness).finess?.rs || "N/A",
    )
  }

  // ########## Date & lieu ##########
  line = addDateLocation(pdf, declaration, line)

  // ########## Faits ##########
  line = addFacts(pdf, declaration, line)

  // ########## Motifs ##########
  line = addReasons(pdf, declaration, line)

  // ########## Victimes & auteurs ##########
  line = addVictimsAuthors(pdf, declaration, line)

  // ########## Précisions ##########
  addPrecisions(pdf, declaration, line)

  // Renvoi du PDF généré
  return pdf.save(
    "onvs-declaration-du-" +
      format(
        parseISO(
          (declaration.createdAt
            ? declaration.createdAt
            : new Date().toJSON().slice(0, 10)) as unknown as string,
        ),
        "dd-MM-yyyy",
      ) +
      ".pdf",
  )
}

function addImageConfidential(pdf: jsPDF): void {
  const img = new Image()
  img.src = "/confidentiel.jpg"
  pdf.addImage(img, "JPEG", 160, 270, 40, 20)
}

function addDateLocation(
  pdf: jsPDF,
  declaration: DeclarationModel,
  line: number,
) {
  line = addSection(pdf, line, "Date & lieu")

  // Date de la déclaration
  line = addLabelAndValue(
    pdf,
    line,
    "Date de la déclaration",
    true,
    format(
      parseISO(
        (declaration.createdAt
          ? declaration.createdAt
          : new Date().toJSON().slice(0, 10)) as unknown as string,
      ),
      "dd/MM/yyyy",
    ),
  )

  // Profession du déclarant
  if (declaration.job) {
    line = addLabelAndValue(
      pdf,
      line,
      "Profession du déclarant",
      true,
      declaration.job,
    )
  }

  // Date de l'évènement
  line = addLabelAndValue(
    pdf,
    line,
    "Date de l'évènement",
    true,
    format(parseISO(declaration.date as unknown as string), "dd/MM/yyyy"),
  )

  // Horaire
  line = addLabelAndValue(pdf, line, "Horaire", true, declaration.hour)

  // Ville
  line = addLabelAndValue(pdf, line, "Ville", true, declaration.town)

  // Code postal
  line = addLabelAndValue(
    pdf,
    line,
    "Code postal",
    true,
    declaration.postalCode,
  )

  /**
   * Pour les libéraux :
   * -> Dans quel lieu précisément ?
   *
   * Pour les établissements :
   * -> Dans quel type d'établissement ?
   * -> Dans quel secteur précisément ?
   * -> Dans quel lieu précisément ?
   */
  const location = declaration.location as Prisma.JsonObject
  Object.keys(location).forEach((detail) => {
    line = addLabelAndValue(
      pdf,
      line,
      detail,
      true,
      processJsonValueLocation(location[detail] as Prisma.JsonValue),
    )
  })

  return line
}

function addFacts(pdf: jsPDF, declaration: DeclarationModel, line: number) {
  line = addSection(pdf, line, "Faits")

  const factPersons = declaration.factPersons as Prisma.JsonObject
  const factGoods = declaration.factGoods as Prisma.JsonObject
  const { factPersonsLevel, factGoodsLevel } = declaration

  // Atteinte aux personnes
  if (factPersons && Boolean(Object.keys(factPersons).length)) {
    line = addText(
      pdf,
      line,
      "Atteinte aux personnes" +
        (factPersonsLevel ? "  ( Niveau " + factPersonsLevel + " )" : ""),
      true,
    )

    // Parcours des groupes de faits
    Object.keys(factPersons).forEach((factGroup) => {
      // Ajout du groupe
      line = addGroup(pdf, line, factGroup)

      // Ajout des faits du groupe
      const facts = factPersons[factGroup] as Prisma.JsonArray
      facts.map((fact) => {
        line = addGroupDetail(pdf, line, processJsonValue(fact))
      })
    })
  }

  // Atteinte aux biens
  if (factGoods && Boolean(Object.keys(factGoods).length)) {
    // On ajoute un espacement complémentaire si une atteinte aux personnes a également été saisie
    if (factPersons && Boolean(Object.keys(factPersons).length)) {
      line += defaultSpacing
    }

    line = addText(
      pdf,
      line,
      "Atteinte aux biens" +
        (factGoodsLevel ? "  ( Niveau " + factGoodsLevel + " )" : ""),
      true,
    )

    // Parcours des groupes de faits
    Object.keys(factGoods).forEach((factGroup) => {
      // Ajout du groupe
      line = addGroup(pdf, line, factGroup)

      // Ajout des détails du groupe
      const details = factGoods[factGroup] as Prisma.JsonArray
      details.map((detail) => {
        line = addGroupDetail(pdf, line, processJsonValue(detail))
      })
    })
  }

  return line
}

function addReasons(pdf: jsPDF, declaration: DeclarationModel, line: number) {
  line = addSection(pdf, line, "Motifs")
  const reasons = declaration.reasons as Prisma.JsonObject

  if (declaration.reasonNotApparent) {
    // Pas de motif apparent
    line = addText(pdf, line, "Pas de motif apparent", false)
  } else {
    Object.keys(reasons).forEach((reasonGroup) => {
      // Ajout du groupe
      line = addGroup(pdf, line, reasonGroup)

      // Ajout des détails du groupe
      const details = reasons[reasonGroup] as Prisma.JsonArray
      details.map((detail) => {
        line = addGroupDetail(pdf, line, processJsonValue(detail))
      })
    })
  }

  return line
}

function addVictimsAuthors(
  pdf: jsPDF,
  declaration: DeclarationModel,
  line: number,
) {
  line = addSection(pdf, line, "Victimes & auteurs")

  const victims = declaration.victims as Prisma.JsonArray
  const authors = declaration.authors as Prisma.JsonArray
  const pursuit = declaration.pursuit as PursuitSchema
  const pursuitBy = pursuit && pursuit["pursuitBy"]
  const thirdParty = declaration.thirdParty as Prisma.JsonArray

  // Victimes
  victims.forEach((victim: VictimSchema, victimIndex) => {
    line = addText(pdf, line, "Victime n°" + (victimIndex + 1), true)

    const victimGenderInfo = victim?.gender
      ? ` de sexe ${victim.gender.toLowerCase()}`
      : ""
    const victimAgeInfo = victim?.age ? ` et âgé de ${victim.age}` : ""

    // Informations sur la victime
    line = addText(
      pdf,
      line,
      victim.type +
        victimGenderInfo +
        victimAgeInfo +
        (victim.healthJob === undefined
          ? "."
          : " dont la profession est " + victim.healthJob.toLowerCase() + "."),
      false,
    )

    line += defaultSpacing

    // Jours d'arrêt de travail
    if (victim?.sickLeaveDays !== undefined) {
      line = addLabelAndValue(
        pdf,
        line,
        "Jours d'arrêt de travail",
        false,
        victim.sickLeaveDays.toString(),
      )
    }

    // Jours d'hospitalisation
    if (victim?.hospitalizationDays !== undefined) {
      line = addLabelAndValue(
        pdf,
        line,
        "Jours d'hospitalisation",
        false,
        victim.hospitalizationDays.toString(),
      )
    }

    // Jours d'ITT
    if (victim?.ITTDays !== undefined) {
      line = addLabelAndValue(
        pdf,
        line,
        "Jours d'ITT",
        false,
        victim.ITTDays.toString(),
      )
    }

    line += defaultSpacing
  })

  // Suites judiciaires
  if (pursuit) {
    line = addLabelAndValue(
      pdf,
      line,
      "Suites judiciaires",
      false,
      pursuit.type.toString(),
    )
  }

  // Par
  if (pursuitBy && pursuitBy.length > 0) {
    pursuitBy.forEach((pursuitByValue, pursuitByIndex) => {
      const label = pursuitByIndex === 0 ? "Par" : ""
      line = addLabelAndValue(pdf, line, label, false, pursuitByValue)
    })
  }

  line += defaultSpacing

  // Auteurs
  authors.forEach((author: AuthorSchema, authorIndex) => {
    line = addText(pdf, line, "Auteur n°" + (authorIndex + 1), true)

    const authorGenderInfo = author?.gender
      ? ` de sexe ${author.gender.toLowerCase()}`
      : ""
    const authorAgeInfo = author?.age ? ` et âgé de ${author.age}` : ""

    // Informations sur l'auteur
    line = addText(
      pdf,
      line,
      author.type +
        authorGenderInfo +
        authorAgeInfo +
        (author.healthJob === undefined
          ? "."
          : " dont la profession est " + author.healthJob.toLowerCase() + "."),
      false,
    )

    line += defaultSpacing

    // Altération du discernement
    const discernmentTroublesLabel = "Altération du discernement"
    if (author.discernmentTroubles && author.discernmentTroubles.length > 0) {
      author.discernmentTroubles.forEach(
        (discernmentTroubleValue, troubleIndex) => {
          const label = troubleIndex === 0 ? discernmentTroublesLabel : ""
          line = addLabelAndValue(
            pdf,
            line,
            label,
            false,
            discernmentTroubleValue,
          )
        },
      )
    } else {
      line = addLabelAndValue(pdf, line, discernmentTroublesLabel, false, "Non")
    }

    line += defaultSpacing
  })

  // Intervention de tiers
  if (thirdParty) {
    thirdParty.forEach((thirdPartyValue, thirdPartyIndex) => {
      const label = thirdPartyIndex === 0 ? "Intervention de tiers" : ""
      line = addLabelAndValue(
        pdf,
        line,
        label,
        false,
        processJsonValue(thirdPartyValue),
      )
    })
  }

  return line
}

function addPrecisions(
  pdf: jsPDF,
  declaration: DeclarationModel,
  line: number,
) {
  line = addSection(pdf, line, "Précisions")

  // Description
  line = addLabelAndValue(
    pdf,
    line,
    "Description",
    true,
    declaration.description,
  )

  // Consentement
  line = addLabelAndValue(
    pdf,
    line,
    "Consentement",
    true,
    declaration.declarantContactAgreement ? "Oui" : "Non",
  )

  if (declaration.declarantContactAgreement) {
    // Nom Prénom
    line = addLabelAndValue(
      pdf,
      line,
      "Nom Prénom",
      true,
      declaration.declarantNames,
    )

    // N° RPPS/ADELI
    line = addLabelAndValue(
      pdf,
      line,
      "N° RPPS/ADELI",
      true,
      declaration.declarantExternalId,
    )

    // Courriel
    line = addLabelAndValue(
      pdf,
      line,
      "Courriel",
      true,
      declaration.declarantEmail,
    )

    // Téléphone
    line = addLabelAndValue(
      pdf,
      line,
      "Téléphone",
      true,
      declaration.declarantTel,
    )
  }

  return line
}

function getDeclarationType(declaration: DeclarationModel) {
  switch (declaration.declarationType) {
    case "ets": {
      return "Établissement"
    }
    case "liberal": {
      return "Libéral"
    }
    default: {
      return "N/A"
    }
  }
}

function processJsonValue(jsonValue: Prisma.JsonValue) {
  return Array.isArray(jsonValue)
    ? jsonValue?.[0] + " (" + jsonValue?.[1] + ")"
    : jsonValue?.toString()
}

const processJsonValueLocation = (jsonValue) => {
  if (Array.isArray(jsonValue) && jsonValue?.[0] === "Autre")
    return `${jsonValue?.[0]} (${jsonValue?.[1]})`
  else if (Array.isArray(jsonValue))
    return jsonValue
      ?.map((val: string, index: number) =>
        index === 0 ? `${val} ` : ` ${val} `,
      )
      .join(" ")
  else return jsonValue
}

function checkForPageBreak(pdf: jsPDF, line: number) {
  // La ligne dépasse la taille de la page
  if (line > pageBreakLine) {
    pdf.addPage() // On créé une nouvelle page
    addImageConfidential(pdf)
    line = startLine // On se repositionne au début de la page
  }

  return line
}

function addSection(pdf: jsPDF, line: number, value) {
  // Positionnement préalable
  line += sectionSpacing

  // On vérifie s'il ne faut pas passer à la page suivante
  line = checkForPageBreak(pdf, line)

  // Section
  pdf.setFont(defaultFontName, "normal", "bold")
  pdf.setFontSize(sectioncFontSize)
  pdf.setFillColor(sectionTitleColor)
  pdf.rect(
    marginLeft,
    line - 5,
    pdf.internal.pageSize.getWidth() - marginLeft * splitFactor,
    sectionHeightSize,
    "F",
  )
  pdf.text(value, pdf.internal.pageSize.getWidth() / splitFactor, line, {
    align: "center",
  })

  // Ajout d'un espacement
  line += defaultSpacing

  return line
}

function addText(pdf: jsPDF, line: number, text: string, boldText: boolean) {
  // Positionnement préalable
  line += defaultSpacing

  // On vérifie s'il ne faut pas passer à la page suivante
  line = checkForPageBreak(pdf, line)

  // Valeur
  pdf.setFont(defaultFontName, "normal", boldText ? "bold" : "normal")
  pdf.setFontSize(defaultFontSize)
  pdf.text(text, textLabelPosition, line)

  return line
}

function addLabelAndValue(
  pdf: jsPDF,
  line: number,
  label: string,
  boldLabel: boolean,
  value: string | undefined | null,
) {
  // Positionnement préalable
  line += defaultSpacing

  // On vérifie s'il ne faut pas passer à la page suivante
  line = checkForPageBreak(pdf, line)

  // Champ
  pdf.setFont(defaultFontName, "normal", boldLabel ? "bold" : "normal")
  pdf.setFontSize(defaultFontSize)
  pdf.text(label, textLabelPosition, line)

  // Valeur

  // On définit une valeur par défaut si celle-ci n'est pas définie
  value = value !== undefined && value !== null ? value : "N/A"

  /**
   * Si la valeur dépasse la largeur de la page, on passe par une autre méthode
   * afin d'ajouter des sauts de ligne
   */
  const valueMaxWidth =
    pdf.internal.pageSize.getWidth() - textValuePosition * splitFactor

  if (value.length > valueMaxWidth) {
    line = addLengthyText(pdf, line, value, textValuePosition, valueMaxWidth)
  } else {
    pdf.setFont(defaultFontName, "normal", "normal")
    pdf.text(value, textValuePosition, line)
  }

  return line
}

function addGroup(pdf: jsPDF, line: number, value: string) {
  // Positionnement
  line += defaultSpacing

  // On vérifie s'il ne faut pas passer à la page suivante
  line = checkForPageBreak(pdf, line)

  // Valeur
  pdf.setFont(defaultFontName, "normal", "normal")
  pdf.setFontSize(defaultFontSize)
  pdf.text(value + " :", textLabelPosition, line)

  return line
}

function addGroupDetail(pdf: jsPDF, line: number, value: string | undefined) {
  // Positionnement préalable
  line += defaultSpacing

  // On vérifie s'il ne faut pas passer à la page suivante
  line = checkForPageBreak(pdf, line)

  // Valeur

  // Ajout à la valeur d'un tiret et/ou d'un N/A si la valeur n'est pas définie
  value = "- " + (value ? value : "N/A")

  /**
   * Si la valeur dépasse la largeur de la page, on passe par une autre méthode
   * afin d'ajouter des sauts de ligne
   */
  const valueMaxWidth = pdf.internal.pageSize.getWidth() / splitFactor

  if (value.length > valueMaxWidth) {
    line = addLengthyText(pdf, line, value, textLabelPosition, valueMaxWidth)
  } else {
    pdf.setFont(defaultFontName, "normal", "normal")
    pdf.setFontSize(defaultFontSize)
    pdf.text(value, textLabelPosition + 5, line)
  }

  return line
}

function addLengthyText(
  pdf: jsPDF,
  line: number,
  text: string,
  position: number,
  maxWidth: number,
) {
  // Configuration générale
  pdf.setFont(defaultFontName, "normal", "normal")
  pdf.setFontSize(defaultFontSize)

  // On retire du texte les éventuels sauts de ligne
  text = text.replace(/(\r\n|\n|\r)/gm, " ")

  // On découpe le texte en une liste de mots
  const words = text.split(" ")

  // "Ligne de mots" à écrire dans le PDF
  let wordLine = ""

  // Parcours du tableau des mots
  words.forEach((word: string) => {
    // On ne traite que les données non vides
    if (word !== "") {
      // Si avec le mot courant, la ligne dépasse de la page : on écrit la ligne dans le PDF
      if ((wordLine + " " + word).length > maxWidth) {
        // On vérifie s'il ne faut pas passer à la page suivante
        line = checkForPageBreak(pdf, line)
        // Ecriture de la ligne
        pdf.text(wordLine, position, line)
        // Saut de ligne
        line += defaultSpacing
        // Réinitialisation de la "ligne de mots"
        wordLine = ""
      }
      // Dans tous les cas, on ajoute un mot
      wordLine = wordLine === "" ? word : wordLine + " " + word
    }
  })

  // On écrit la toute dernière "ligne de mots" dans le PDF
  pdf.text(wordLine, position, line)

  return line
}
